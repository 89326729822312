import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../components/hero'
import JobOffers from '../components/job-offers'

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "job-offer.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Nabídka zaměstnání" />

      <Hero title="Nabídka zaměstnání" subtitle="Podívejte se na nabízená volná místa" image={data.image} />

      <JobOffers />
    </Layout>
  )
}

export default IndexPage
