import React from 'react'
import { Box, Text } from 'rebass/styled-components'

import Section from './section'
import Container from './container'
import Cards from './cards'

const cards = [
  {
    html: 'SVÁŘEČE',
  },
  {
    html: `TOPENÁŘE`,
  },
  {
    html: `INSTALATÉRY`,
  },
  {
    html: `ZEDNÍKY A STAVEBNÍ TECHNIKY`,
  },
]

const Environment = () => {
  return (
    <Section>
      <Container width="60rem">
        <Box>
          <Text variant="body" as="p">
            Vedení společnosti Czech heat service s.r.o. přijme do pracovního
            poměru:
          </Text>

          <Box sx={{ mb: 4 }}>
            <Cards direction="row" cards={cards} />
          </Box>

          <Text variant="body" as="p">
            Czech heat service s.r.o. se zavazuje k trvalému zlepšování vztahu
            společnosti k životnímu prostředí v mezích svých ekonomických
            zdrojů.
          </Text>

          <Text variant="body" as="p">
            Více informací na e-mailu&nbsp;
            <a href="mailto:prace@czech-heat.cz">prace@czech-heat.cz</a>
            .
          </Text>
        </Box>
      </Container>
    </Section>
  )
}

export default Environment
